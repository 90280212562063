* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

\ {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.project_wrapper {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
  background-color: white;
}
.lg-container {
  max-width: 1550px;
}

.lg-container p {
  font-size: 22px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
}

.lg-container h1 {
  font-size: 48px;
}

/* body {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #34495f !important;
  font-family: Inter-Regular !important;
} */

.bg-blue-con-wht {
  /* background-color: #fff;
  color: #000;*/
  padding-top: 5%;
  padding-bottom: 12%;
  background-color: #f2f2f2;
}

.blue-heading-roy {
  color: #0066cc !important;
}

.bg-blue-con {
  background-color: #eceef1;
  color: #000;
}
.bg-blue-con-dark {
  background-color: #eaeaea;
  color: #000;
  padding-top: 10%;
  padding-bottom: 10%;
}

.topbgimg {
  background-image: url(../webImages/top_g.png);
  background-repeat: no-repeat;
  background-position: top left;
  width: 274px;
  height: 133px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

.rightimg {
  background-image: url(../webImages/right_g.png);
  background-repeat: no-repeat;
  background-position: top left;
  width: 100px;
  height: 320px;
  position: absolute;
  right: 0;
  z-index: 9999;
}

.rightblueimg {
  background-image: url(../webImages/rightblue_g.png);
  background-repeat: no-repeat;
  background-position: top left;
  width: 79px;
  height: 450px;
  position: absolute;
  right: 0;
  z-index: 9999;
}

.leftimg {
  background-image: url(../webImages/left_g.png);
  background-repeat: no-repeat;
  background-position: top left;
  width: 86px;
  height: 343px;
  position: absolute;
  top: 50;
  left: 0;
  z-index: 9999;
}

.search_dropdown_book button {
  padding: 15px;
  background-color: #0066cc;
  border-color: #0066cc;
}

.custom-color {
  color: #0066cc;
}

.lessons_lt {
  background: #fff !important;
}

.lessons_lt .bookContent {
  font-size: 22px;
  font-family: 'Source Serif 4', 'Source Serif Pro', 'Georgia Pro', Georgia, serif;
}

@media screen and (max-width: 767px) {
  .lessons_lt .bookContent {
    font-size: 18px;
    line-height: 28px;
  }
}

/* .bookContent img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100% !important;
  /* min-width: 350pt !important; 
}
*/

summary {
  margin: -0.5em -0.5em 0em;
  padding: 0.5em;
}
details {
  border-left: 5px solid #0066cc; /* #00A1FF #fcc015;*/
  /*border-radius: 0.2rem;*/
  padding: 0.5rem;
}

details[open] {
  padding: 0.5em;
  background-color: #F0F7FF;
}

details[open] summary {
  border-bottom: 1px solid #aaa;
  margin-bottom: 0.5em;
  color: #0066cc;
}

body {
  font-family: 'Source Serif Pro', serif;
  color: #34495e;
}

.h1,
.h2,
.h3,
.h4,
.h5,
h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.h6,
h6 {
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
table {
  border-collapse: collapse;
  caption-side: bottom;
  margin: auto;
  font-size: 18px;
}

/* h1 {
  font-size: 2rem !important;
}

h2 {
  font-size: 1.8rem !important;
}

h3 {
  font-size: 1.6rem !important;
}

h4 {
  font-size: 1.4rem !important;
}

h5 {
  font-size: 1.2rem !important;
}

h6 {
  font-size: 1rem !important;
} */

.dropdown-toggle::after {
  color: white;
}

.default-black-btn {
  background-color: #00a1ff;
  padding: 7px 45px;
  color: #fff;
  border-radius: 10px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #00a1ff;
  font-weight: 500;
}

.default_white_btn {
  background-color: #00a1ff;
  padding: 7px 10px;
  color: #000;
  border-radius: 10px;
  transition: 0.5s;
  border: 2px solid #000000;
  font-weight: 500;
}

.default-black-btn:hover {
  background-color: transparent;
  color: #0066cc;
}

._transparent_white {
  background-color: transparent;
  padding: 10px;
  color: #fff;
}

._transparent_white:hover {
  background-color: transparent;
  color: #fff;
}

.navbar {
  /* background-color: #0b9ed0; */
  background-color: #fff;
  color: white;
  padding: 0 50px;
  height: 100px;
}

.navbar-brand img {
  width: 140px;
  height: auto;
}

.nave-left {
  margin-left: auto;
  /* padding: 5px 50px 50px 10px;
  margin-bottom: 50px;
  min-height: 500px;  */
}

.sub-nav {
  border-bottom: 2px solid #000000;
}

.nave-left a {
  color: #fff !important;
  font-weight: 300;
  margin-right: 20px;
}

a.nav-link {
  padding: 18px 0 12px 0px;
}

.nav-link.active {
  border-bottom: 2px solid #ffffff !important;
  color: #ffffff !important;
}

a.nav-link:hover {
  border-bottom: 2px solid #fff;
}

.nave-left .dropdown-item {
  color: #0e2465 !important;
  font-weight: 400;
}

.nave-left .dropdown-toggle {
  display: flex;
  align-items: center;
}

.nave-left .dropdown-toggle a {
  margin-right: 0 !important;
}

.H-slider-img-box {
  background: #0b9ed0;
  margin: 35px;
  margin-right: 45px;
}

.H-slider-img-box img {
  border-radius: 10px;
  padding: 11px;
  margin-left: -30px;
}

/* swipper */
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  color: #0b9ed0 !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* swipper-close */
.H-slider {
  background-image: url(../webImages/H_slider_back.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.H-slider-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.H-slider-text h1 {
  font-size: 48px;
  font-weight: bold;
  color: #00a1ff;
  line-height: 48px;
}

.H-slider-text hr {
  color: #067499;
  height: 5px;
  margin-bottom: 30px;
}

.H-slider-text h4 {
  font-size: 36px;
  color: black;
  margin-bottom: 20px;
}

.H-slider-text p {
  color: #34495e;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif !important;
}

.H-slider-text .btn {
  background-color: #00a1ff;
  padding: 7px 45px;
  color: #fff;
  border-radius: 10px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #00a1ff;
  font-weight: 500;
}

.H-slider-text .btn:hover {
  box-shadow: 0px 4px 4px rgba(65, 105, 225, 0.25);
  border: 2px solid #000000;
  background-color: transparent;
  color: #000000;
}

.H-sec2-text {
  text-align: center;
  margin-top: 50px;
}

.H-sec2-text h1 {
  color: black;
}

.H-sec2-text hr {
  margin: auto;
  /* height: 2px;
    color: black; */
  border: 2px solid black;
  margin-top: 5px;
  margin-bottom: 20px;
}

.H-sec2-text p {
  font-size: 16px;
  color: gray;
}

.H-cont-box-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.H-cont-box-text h2 {
  color: black;
  margin-bottom: 20px;
}

.H-cont-box-text p {
  font-size: 16px;
  color: gray;
}

.H-cont-img {
  padding: 20px 20px 20px 0px;
}

.H-cont-box-text .btn {
  background-color: #000000;
  padding: 7px 45px;
  color: #fff;
  border-radius: 10px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #000000;
  font-weight: 500;
}

.H-cont-box-text .btn:hover {
  box-shadow: 0px 4px 4px rgba(65, 105, 225, 0.25);
  border: 2px solid #000000;
  background-color: transparent;
  color: #000000;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  display: none;
}

/* Home Gif */
.Hgif {
  background-color: #3a3839;
  padding: 20px 30px 60px 30px;
  /* margin-top: 20px; */
}

/* .Hgif h1 {
  color: rgb(255, 255, 255);
}

.Hgif hr {
  margin: auto;
  border: 2px solid rgb(255, 255, 255);
  margin-top: 5px;
  margin-bottom: 20px;
}

.Hgif p {
  font-size: 16px;
  color: rgb(255, 255, 255);
}

.Hgif .gif {
  margin-top: 40px;
  border-radius: 15px;
}

.Hgif .gif img {
  border-radius: 15px;
  width: 60%;
  height: 400px;
} */
.top-home-back {
  background-color: rgb(247, 247, 247);
  padding: 50px 0;
  overflow: hidden;
}

._search_wrapper {
  background-color: rgb(247, 247, 247);
  padding: 50px 0;
}

/* --------------------//////////////////////////// About-css //////////////////////////------------------------------ */
.about-back {
  background-image: url(../webImages/about-back1.png);
  background-position: center center;
  background-size: cover;
  padding: 200px 20px;
}

._payment_image {
  width: 220px;
  margin: 10px auto;
  height: 220px;
  object-fit: cover;
  border-radius: 5px;
}

.about-back h1 {
  color: #fff;
  text-align: center;
}

.about-back p {
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.about-back hr {
  color: #fff;
  width: 250px;
  margin: auto;
  margin-bottom: 15px;
  height: 2px;
}

.a-text-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}

.a-text-sec h2 {
  margin-bottom: 20px;
  color: black;
}

.a-text-sec p {
  color: gray;
  font-size: 16px;
}

.about-img-box img {
  border-radius: 10px;
}

.about-img-box {
  padding: 20px 20px 20px 0px;
}

.a-sec2-back {
  /* background-color: #3A3839; */
  background-image: url(../webImages/about-back-center.png);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  padding: 50px 0;
}

.a-sec2-back h2 {
  color: #fff;
}

.a-sec2-back p {
  color: #fff;
}

.a-sec3 h2 {
  color: black;
}

.a-sec3 hr {
  color: black;
  border: 2px solid;
}

.a-sec3 .card-body h5 {
  color: black;
}

.a-sec3 .card-body p {
  color: gray;
}

.a-sec3 .card {
  margin-top: 50px;
}

.a-sec3 .card-img {
  overflow: hidden;
}

.a-sec3 .card-img img {
  transition: transform 0.2s;
}

.a-sec3 .card-img img:hover {
  transform: scale(1.1);
}

.a-text-sec .about-scroll {
  max-height: 200px;
  overflow-y: scroll;
}

.about-scroll::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.about-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.about-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.about-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* --------------------////////////////////////////-Contact-//////////////////////////------------------------------ */

.contact-back {
  background-image: url(../webImages/contact.png);
  background-position: center center;
  background-size: cover;
  padding: 200px 20px;
}

.contact-back h1 {
  color: #fff;
  text-align: center;
  margin-top: 2rem;
}

.contact-back p {
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.contact-back hr {
  color: #fff;
  width: 250px;
  margin: auto;
  margin-bottom: 15px;
  height: 2px;
}

.contact-text ul {
  color: gray;
  font-size: 15px;
}

.contact-text h1 {
  color: black;
}

.form-box {
  padding: 50px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.form-box Form .form-control {
  border: none;
  border-bottom: 2px solid #0066cc;
  border-radius: 0px;
}

.form-box Form .form-control:focus {
  box-shadow: none;
}

.form-box Form .btn {
  background-color: #0066cc;
  padding: 6px 50px;
  color: #fff;
  border-radius: 10px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #0066cc;
}

.form-box Form .btn:hover {
  box-shadow: 0px 4px 4px rgba(65, 105, 225, 0.25);
  border: 2px solid #0066cc;
  background-color: transparent;
  color: #0066cc;
  font-weight: 500;
}

/* login-signup */
.login-back {
  background-image: url(../webImages/loginimg.png);
  background-position: center center;
  background-size: cover;
  padding: 100px 0px;
}

.lagincontainer {
  padding: 0px !important;
}

.login-back h2 {
  text-align: center;
  color: #fff;
}

.Lmargin a {
  margin-left: 2px;
}

.ragister-back {
  background-image: url(../webImages/register.png);
  background-position: center center;
  background-size: cover;
  padding: 150px 20px;
}

.ragister-back h1 {
  color: #fff;
  text-align: center;
}

.ragister-back p {
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.ragister-back hr {
  color: #fff;
  width: 250px;
  margin: auto;
  margin-bottom: 15px;
  height: 2px;
}

/* --------------------////////////////////////////-Blog-//////////////////////////------------------------------ */

.blog_header {
  background-position: center center;
  background-size: cover;
  padding: 200px 40px;
  text-align: center;
  background-repeat: no-repeat;
}

.blog_header h1 {
  color: #ffffff;
  font-size: 50px;
}

.blog_header p {
  color: #fff;
  font-size: 20px;
}

.our_blog_head {
  padding-bottom: 60px;
}

.our_blog_head h1 {
  border-bottom: 1px solid #000000;
  width: 22%;
  margin: 0 auto;
  font-size: 32px;
}

.our_blog {
  padding: 40px 20px;
}

.categoreis {
  background-color: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
  padding: 20px 15px;
  position: sticky;
  top: 0px;
}

.categoreis p {
  font-size: 16px;
}

.categori_searchbar input {
  border-radius: 10px;
  padding: 4px 10px;
  width: 85%;
  border: none;
}

.categoreis_text {
  padding: 10px;
  margin-top: 10px;
  text-align: left;
}

.categoreis_text p {
  font-size: 15px;
  margin-bottom: 0px;
}

.categoreis_text hr {
  margin-bottom: 5px;
}

.categoreis_text ul {
  list-style: none;
  padding: 0;
  margin: 12px 0 0 0;
}

.categoreis_text ul li {
  display: block;
  margin: 5px 0;
  color: #3a3839;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 10px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.categoreis_text ul li span {
  float: right;
}

.categoreis_text ul li.isActive {
  color: #0066cc;
  border-color: #0066cc;
}

.categoreis_text ul li:hover {
  color: #0066cc;
  border-color: #0066cc;
}

.our_blogs_row {
  margin-bottom: 20px;
  text-align: left;
  background-color: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.our_blog_rightcontent {
  padding-top: 20px;
  cursor: pointer;
}

.blog_latesposts {
  padding: 10px;
}

/* .ourblog_latesposts {
  padding: 0 10px;
} */

.latesposts_row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background: #0077f71a;
  padding: 5px;
  border-radius: 8px;
}

.latesposts_row img {
  width: 55px;
  height: 55px;
  border-radius: 50px;
  object-fit: cover;
}

.latesposts_row p {
  margin-bottom: 2px;
  font-size: 15px;
  text-align: left;
}

.App {
  font-family: sans-serif;
}

.editorContainer {
  height: 9em;
}

.editors {
  border: 1px gray solid;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-top: 0.5em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif;
  font-size: 90%;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
}

/* --------------------////////////////////////////-Forum-close-css-//////////////////////////--------------------- */

.F-box {
  background-color: #fff;
  padding: 25px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.F-box h4 {
  color: #0e2466;
}

.F-box2 {
  background-color: #fff;
  padding: 25px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.F-box2 .btn {
  background-color: #0066cc;
  padding: 7px 45px;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #0066cc;
  font-weight: 500;
}

.F-box2 .btn:hover {
  box-shadow: 0px 4px 4px rgb(65 105 225 / 25%);
  border: 2px solid #0066cc;
  background-color: transparent;
  color: #0066cc;
}

.F-accordiun {
  background-color: rgb(245, 245, 245);
  margin-bottom: 5px;
  margin-top: 5px;
}

.F-contain {
  display: flex;
}

.F-box .top-hr {
  margin-top: 0px;
  border: 2px solid #fff;
}

.F-box2 hr {
  margin-top: 0px;
}

.F-box2 p {
  margin-bottom: 5px;
  font-weight: 500;
}

/* //// --------------------------------Forum-close css------------------------------------------ */

/* --------------------/////////////////////footer/////////////////------------------------------ */
.footer {
  background-color: black;
  padding: 50px 0 30px 0;
  /* margin-top: 40px; */
}

.footer p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
  color: #fff;
  font-size: 16px;
  margin-top: 30px;
  width: 80%;
  line-height: 1.5;
  font-weight: 300;
}

.footer h5 {
  color: #fff;
  font-size: 30px;
  margin-top: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
  /*font-family: 'Montserrat', sans-serif;*/
  font-weight: bold;
}

.footer ul {
  text-decoration: none;
  list-style-type: none;
  margin-top: 28px;
  padding-left: 0px;
}

.footer ul li {
  margin-bottom: 5px;
  color: #fff;
  font-size: 15px;
}

.footer ul li:hover {
  color: #0b9ed0;
}

.footer ul li a {
  color: #fff;
  font-weight: 300;
  text-decoration: none;
  transition: 0.2s all ease-in-out;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
}

.footer ul li a:hover {
  color: #0b9ed0;
  text-decoration: none;
}

.footer .icon {
  color: #fff;
  font-size: 25px;
  margin-right: 20px;
  margin-top: 20px;
}

.footer .icon:hover {
  color: #0b9ed0;
  cursor: pointer;
}

.footer .para {
  color: #fff;
  font-size: 15px;
  text-align: center;
  width: 100%;
}

.footer img {
  width: 200px;
}

.profile-card {
  box-shadow: 0px 0px 4px 0px #00000038;
  padding: 35px;
  position: relative;
  z-index: 3;
  background: #fff;
  border-radius: 10px;
  width: 75%;
  margin: auto;
  margin-top: -60px;
}

.profile-img-area {
  width: 190px;
  height: 190px;
  box-sizing: border-box;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%),
    0px 1px 5px 0px rgb(145 158 171 / 12%);
  margin-top: -100px;
  margin-right: auto;
  margin-left: auto;
}

.profile-img-area img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.profile-content-area {
  padding: 0 20px;
}

.profile-heading {
  border-bottom: 1px dashed #0066cc;
  margin-bottom: 10px;
}

.profile-heading h5 {
  color: #0066cc;
}

.default-btn {
  background-color: #0066cc;
  padding: 7px 15px;
  color: #fff;
  border-radius: 10px;
  transition: 0.5s;
  /* margin-top: 10px; */
  border: 2px solid #0066cc;
}

.default-btn:hover {
  box-shadow: 0px 4px 4px rgba(65, 105, 225, 0.25);
  border: 2px solid #0066cc;
  background-color: transparent;
  color: #0066cc;
  font-weight: 500;
}

.default-btn svg {
  font-size: 22px;
  margin-right: 5px;
  vertical-align: bottom;
}

/* //// image uplaod with preview */

.avatar-upload {
  position: relative;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit label svg {
  font-size: 20px;
  color: #0066cc;
}

.avatar-upload .avatar-preview {
  position: relative;
  width: 190px;
  height: 190px;
}

.avatar-upload .avatar-preview > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.white-btn {
  background-color: #00a1ff;
  color: #fff;
  border-color: #00a1ff;
  font-weight: 500;
  margin-top: 10px;
  box-shadow: none;
}

.white-btn:hover {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
  font-weight: 500;
  box-shadow: none;
}

.white-btn:active,
.white-btn:focus {
  background-color: #fff;
  border-color: #fff;
  color: #0b9ed0;
  box-shadow: none;
}

.white-btn svg {
  margin-right: 0px;
}

.shape {
  background-position: center;
  height: 100%;
  transition: all 1s ease-in-out;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 500px;
}

/* //// --------------------------------become an author css------------------------------------------ */

.author_head_sec {
  padding: 40px 0px !important;
  width: 75% !important;
}

.author_head_left {
  padding: 20px 40px;
}

.author_head_right {
  padding: 30px 75px;
}

.author_head_left p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.author_head_sec h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 55px;
  color: #000;
  margin-bottom: 20px;
}

.author_head_sec h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  color: #4951f5;
  margin-bottom: 20px;
}

a.author_btn.btn.btn-primary {
  background-color: #0066cc;
  padding: 7px 45px;
  color: #fff;
  border-radius: 10px;
  transition: 0.5s;
  border: 2px solid #0066cc;
  margin-top: 20px;
  font-weight: 500;
}

a.author_btn.btn.btn-primary:hover {
  box-shadow: 0px 4px 4px rgb(65 105 225 / 25%);
  border: 2px solid #0066cc;
  background-color: transparent;
  color: #0066cc;
}

.educative_rows h2 {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 41px;
  color: #000;
}

.educative_rows hr {
  margin: auto;
  border: 2px solid black;
  margin-top: 5px;
  margin-bottom: 20px;
}

.educative_sec {
  padding: 0px 60px;
}

.educative_rows {
  padding: 0px 40px 35px 40px;
  align-items: center;
}

.interactivity div {
  padding: 40px 65px;
  color: #000;
}

.focus_on h3 {
  color: #000;
}

.educative_sec h3 {
  /* font-size: 36px;
  font-weight: 700; */
  line-height: 41px;
}

.hr_lines hr {
  width: 70%;
  margin: 0 auto;
  color: #000;
  background-color: #000;
  border: 0 none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 1);
}

.testimonial_sec {
  text-align: center;
  padding: 70px !important;
  /* background-color: #3a3839;
  color: #fff; */
}

.accodion-back {
  background-color: #0e2465;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 5px;
}

.faq_wd h1 {
  margin-bottom: 25px;
}

.testimonial_cl img {
  margin: 0 auto;
}

.testimonial_cl {
  text-align: center;
  padding: 50px 40px !important;
}

.testimonial_cl p:nth-child(2) {
  font-weight: 600;
}

.faq_sec {
  padding: 70px 40px !important;
  width: 70% !important;
}

.question_ans {
  border-bottom: 1px solid;
  border: 0 none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
}

p.question {
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  margin-top: 20px;
}

p.ans {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.form_sec {
  padding: 50px 50px;
}

.form_sec_wd {
  width: 100% !important;
  margin: 0 auto !important;
}

.form_sec h1 {
  margin-bottom: 60px;
  text-align: center;
}

.form_cl {
  padding: 0px 30px;
}

.form_img {
  padding: 15px;
}

.form_cl label {
  width: 100%;
  margin-top: 20px;
}

.form_cl label p {
  margin-bottom: 5px;
  color: #000;
  font-weight: 700;
}

.form_cl select {
  width: 100%;
  padding: 12px;
}

.form_cl input {
  width: 100%;
  padding: 12px;
}

.form_cl legend {
  font-size: 14px;
}

.yes_no_inputs input {
  width: 30px;
}

.name_sname label {
  width: 100%;
  display: inline-block;
}

.name_sname label:nth-child(1) {
  margin-right: 7px;
}

.submit_btn input {
  padding: 12px 14px;
  margin-top: 20px;
  background-color: #0066cc;
  border: 1px solid #0066cc;
  border-radius: 2px;
  color: #fff;
}

.custombg {
  padding: 20px 30px 60px 30px;
}

.custombg:nth-child(even) {
  direction: rtl;
  background-color: #fff;
  padding: 50px 0px 75px 0px;
}

.custombg:nth-child(odd) {
  direction: ltr;
  background: rgb(42, 74, 255);
  background: linear-gradient(135deg, #000000, #121212, #002366, #0066cc, #0077c9);
  /*background-color: #001433; */
  padding: 50px 0px 75px 0px;
}

.custombg:nth-child(even) h1 {
  color: black;
}

.custombg:nth-child(even) hr {
  margin: auto;
  /* height: 2px;
    color: black; */
  border: 2px solid black;
  margin-top: 5px;
  margin-bottom: 20px;
}

.custombg:nth-child(odd) .default-black-btn:hover {
  color: #fff;
  border-color: #fff;
}

/* .custombg:nth-child(even) p {
  font-size: 16px;
  color: rgb(255, 255, 255);
}

.custombg:nth-child(even) .description {
  font-size: 16px;
  color: black;
} */

.custombg:nth-child(even) .carousel-caption-custom {
  padding-left: 0;
  padding-right: 25px;
}

.custombg:nth-child(even) .carousel-caption-custom h3 {
  color: black;
}

.custombg:nth-child(even) .carousel-caption-custom p {
  color: black;
}

.custombg:nth-child(odd) .carousel-caption-custom h3 {
  color: #fff;
}

.custombg:nth-child(odd) .carousel-caption-custom p {
  color: #fff;
}

.search-section .card {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.search-section .card img {
  object-fit: cover;
  height: 235px;
  width: 100%;
  max-height: 235px;
}

.custombg:nth-child(odd) .nobookscoming {
  color: #fff;
  text-align: center;
}

.custombg:nth-child(even) .nobookscoming {
  color: #000;
  text-align: center;
}

.custombg:nth-child(even) .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.content-books {
  box-sizing: border-box;
  position: relative;
  max-height: 7.75em;
  padding: 0 5px;
  float: left;
  margin-right: 50px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-books:after {
  content: '\02026';
  position: absolute;
  top: 5.9em;
  right: 25px;
  width: 3em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%, #fff);
  text-align: right;
  vertical-align: bottomm;
}

/* .category-image-section .customrow:nth-child(even) {
  direction: rtl;
  background-color: #fff;
}

.category-image-section .customrow:nth-child(odd) {
  direction: ltr;
  background-color: #3a3839;
} */
.category-image-section {
  overflow: hidden;
}

.category-image-section h1 {
  color: rgb(255, 255, 255);
  font-size: 45px !important;
  font-weight: bold;
}
._adabptSection_title {
  margin-bottom: 50px;
}
._adabptSection_title h1 {
  color: #000;
  text-align: center;
  font-size: 45px !important;
  font-weight: bold;
}
._adabptSection_title hr {
  margin: auto;
  /* height: 2px;
    color: black; */
  border: 2px solid #000;
  margin-top: 5px;
  margin-bottom: 20px;
}

.category-image-section {
  overflow: hidden;
}

.category-image-section hr {
  margin: auto;
  /* height: 2px;
    color: black; */
  border: 2px solid rgb(255, 255, 255);
  margin-top: 5px;
  margin-bottom: 30px;
}

.category-image-section .gif {
  margin-top: 40px;
  border-radius: 15px;
}

.category-image-section .gif img {
  border-radius: 15px;
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.category-image-section .carousel-indicators {
  bottom: -80px;
}

.category-image-section .carousel-control-prev {
  left: -12%;
}

.category-image-section .carousel-control-next {
  right: -12%;
}

.profile-dropdown a {
  text-decoration: none;
  color: #0e2465 !important;
  font-weight: 400;
  width: 100%;
  display: block;
}
.profile-dropdown a:hover {
  border: none !important;
}

.header-section {
  background-color: #000000af;
  position: absolute;
  z-index: 10;
  border-radius: 40px;
  top: 20px;
  width: 90%;
}

@media screen and (max-width: 768px) {
  .header-section {
    top: 0px;
    border-radius: 0px;
    width: 100%;
  }
}

/*.header-section .navbar-brand {
  margin-left: 30px;
} */

.profile-dropdown .dropdown-menu {
  right: 0;
  left: auto;
}

.carousel-caption-custom {
  padding: 0 0 0 25px;
}

/* //// forum design css */
.forum-box {
  margin: 25px 0 0 0;
}

.QA-view {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.QA-view ul {
  list-style: none;
  padding: 0 0 0 15px;
  margin: 0;
}

.asker {
  font-size: 16px;
  color: #4e4e4e;
}

.QA-view ul li {
  display: inline-block;
}

.QA-view ul li {
  margin-right: 10px;
}

.QA-view ul li:last-child {
  margin-right: 0;
}

.QA-view ul li span:first-child {
  color: #6d798b;
  font-size: 14px;
}

.QA-view ul li span.tagsview {
  background: rgb(245, 245, 245);
  padding: 3px;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 7px;
  color: #4e4e4e;
}
.lessons_lt {
  background: #fff !important;
}
.answer-view {
  background-color: rgb(245, 245, 245);
  margin: 20px 0 10px 0;
  overflow-y: scroll;
}

.answer-list {
  padding: 10px;
  border-bottom: 4px solid #fff;
}

.answer-height {
  height: 200px;
}

.answer-list p {
  margin: 0;
}

.answer-list span {
  font-size: 16px;
}

._comment_list {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 0 60px;
}

._comment_list p {
  margin: 0;
}

/* .scrollbar
{
	margin-left: 30px;
	float: left;
	height: 300px;
	width: 65px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px;
}

.force-overflow
{
	min-height: 450px;
}

#wrapper
{
	text-align: center;
	width: 500px;
	margin: auto;
} */

.answer-view::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.answer-view::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.answer-view::-webkit-scrollbar-thumb {
  background-color: #0066cc;
}

/* --------------------////////////////////////////-Blog Details-//////////////////////////------------------------------ */
.border-light hr {
  border-color: #fff !important;
}

.our_blog_leftimg img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  height: 250px;
}

.blog_details_sec {
  padding: 40px;
}

.blog-image-area img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.blog_details_head {
  text-align: left;
  margin-top: 15px;
}

.blog_details_head h3 {
  padding: 30px 0px 27px;
}

.blog_time {
  margin-top: 20px;
  font-style: italic;
  font-size: 11px;
}

.blog_social {
  margin-top: 15px;
  text-align: right;
}

.blog_social .icon {
  margin-right: 8px;
}

.blog_details_content {
  padding: 10px 30px;
}

.blog_details_content p {
  margin-top: 15px;
}

.blog_details_tag {
  font-weight: 700;
}

/* text-editor */
.wrapper-class {
  padding: 1rem;
  border: 1px solid #dce0e4;
  border-radius: 7px;
}

.editor-class {
  background-color: rgb(255, 255, 255);
  padding: 5px 1rem;
  border: 1px solid rgb(231, 231, 231);
}

.toolbar-class {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.iconview {
  width: 100px;
}

.Library_sec_view {
  padding: 90px 0;
  background: #fff;
}

._library_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.616), rgba(0, 0, 0, 0.623)), url(../webImages/LIbrary.png);
}
._library_bg h1 {
  color: #fff !important;
}
._library_bg p {
  color: #fff !important;
}

._library_bg .breadCrumbsarea ol li a:hover {
  color: #fff;
}

.Library-back {
  /* background-image: url(../webImages/LIbrary.png); */
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  overflow: hidden;
}

.Library-back h1 {
  color: #000;
  text-align: center;
}

.Library-back p {
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.Library-back hr {
  color: #fff;
  width: 250px;
  margin: auto;
  margin-bottom: 15px;
  height: 2px;
}

.Library-back .box {
  background-color: rgba(255, 255, 255);
  padding: 50px 0px 0;
}

.card-img {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 15px;
}

.Library .card {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.Library .btn {
  padding: 0px;
}

.Library .btn span {
  border-right: 1px solid black;
}

.Library .btn .btn1 {
  padding: 2px 15px 2px 0px;
  color: #fff;
}

.Library .btn .btn2 {
  padding: 2px 0px 2px 15px;
  color: #fff;
}

.Library .btn .btn1:focus {
  box-shadow: none;
}

.Library .btn .btn2:focus {
  box-shadow: none;
}

.Library h2 {
  text-align: center;
}

.Library hr {
  width: 350px;
  margin: auto;
  height: 3px;
  margin-bottom: 15px;
}

/* .Library .row {
  margin-top: 60px;
  padding: 50px 0;
} */

.latepostimg div {
  width: 55px;
  height: 55px;
}

/* .Library .card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  position: absolute;
  top: 50%;
  overflow: hidden;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin-top: 300px;
  position: absolute;
  width: 100%;
  height: 20px;
  padding: 0px 15px 25px 15px;
  background-color: #fff;
}

.Library .card:hover .card-body {
  margin-top: 221px;
  height: 180px;
  padding-top: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
} */

/* library detail css */
.breadCrumbsarea ol {
  justify-content: center;
}

.breadCrumbsarea ol li a {
  color: #c3c3c3;
  text-decoration: none;
}

.breadCrumbsarea ol li a:hover {
  color: #000;
  text-decoration: underline;
}

.contentWrapperLibrary {
  margin: 35px 0;
}

.contentWrapperLibrary img {
  object-fit: cover;
  width: 100%;
  min-width: 70%;
  /*height: 500px;*/
}

.contentWrapperLibrary .card-body {
  padding: 25px;
}

/* book card css */

:root {
  --surface-color: #fff;
  --curve: 40;
}
.lesson_lt {
  background: #fff;
}
.card-wrap {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.card-wrap img {
  height: 300px;
  object-fit: cover;
}

.card-wrap .card-body {
  padding: 25px 20px;
}

.card_wrapper {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.card__image {
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card_wrapper:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 20px;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path('M 40 80 c 22 0 40 -22 40 -40 v 40 Z');
}

.card_wrapper:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  font-size: 1em;
  margin: 0 0 0.3em;
  color: #0066cc;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: 'MockFlowFont';
  font-size: 0.8em;
  color: #0066cc;
}

.card__status {
  font-size: 0.8em;
  color: #6d798b;
}

.card__description {
  padding: 0 20px 20px;
  margin: 0;
  color: #3a3839;
  font-family: 'MockFlowFont';
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.card__header-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* sidebar css */

/* .library-sidebar {
  background: #f6f6f6;
  width: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
  position: sticky;
  top: 0px;
} */

.library-sidebar {
  /* background:#f6f6f6;
	-webkit-filter:drop-shadow(0 4px 4px rgba(0,0,0,.2));
	filter:drop-shadow(0 4px 4px rgba(0,0,0,.2)); */
  /* position:-webkit-sticky;
	position:sticky;
	top:0;
	width:100% */
  position: -webkit-relative;
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px 0 #c5c5c5;
}

.library-sidebar_new {
  background: #fff;
  -webkit-filter: none;
  filter: none;
  position: -webkit-relative;
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
}

/* .separate_chapter_listing {
    margin: auto !important;
} */
.reusable_drag_listing {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
  width: 25%;
  position: absolute;
  height: 100%;
  background: #ffffff;
  z-index: 50;
}

@media screen and (max-width: 768px) {
  .reusable_drag_listing {
    width: 95%;
  }
}

.sidebarul {
  list-style-type: none;
  padding: 10px 0 0 0;
  margin: 0;
}

.sidebarul li {
  margin: 10px 0;
  cursor: pointer;
  padding: 5px 10px;
}

.sidebarul li.active {
  background: #0066cc;
  color: #fff;
}

.sidebarcontent {
  border-bottom: 1px solid #ddd;
}

.sidebarcontent h6 {
  margin: 0;
}

.sidebarcontent .sidebarul {
  text-align: center;
  padding: 15px 0;
}

.sidebarcontent .sidebarul li {
  display: inline-block;
  border: 1px solid #0066cc;
  border-radius: 5px;
  margin: 0 15px 0 0;
}

.chapterListing {
  /* padding: 0 0 20px 20px; */
  padding: 20px 10px;
}

.chapterListing div div div span {
  text-align: left !important;
}

.numberUl {
  list-style-type: decimal !important;
  padding: 0 0 0 40px !important;
  /* display: none; */
}

.numberUl li {
  margin: 15px 0 !important;
}

.chapterListing ul {
  list-style-type: none;
  padding: 8px;
  margin: 0;
  /* background: lightgrey; */
  /* display: none; */
}
.chapterListing ul li {
  user-select: none;
  padding: 12px;
  margin: 0px 0px 6px;
  text-align: right;
  background: white;
}
.chapterListing ul li ul {
  padding: 0px;
}
.chapterListing ul li ul li {
  padding: 12px;
  margin: 0px;
  text-align: right;
  background: transparent;
}

.applyHeight {
  display: block !important;
  /* transition: visibility 0.2s ease-in-out; */
}

/* .chapterListing ul li {
  margin: 10px 0;
} */

.chapterListing ul li div {
  cursor: pointer;
}

.chapterListing ul li a {
  text-decoration: none;
}

/* sidebar collapse css */

#wrapper {
  display: flex;
  align-items: stretch;
  padding: 0 0 30px 0;
}
.sidebar-collapse + .rightWrapper {
  padding-left: 25% !important;
  transition: all 0.3s;
}
.sidebar-collapse.active + .rightWrapper {
  padding-left: 0% !important;
}
.sidebar-collapse {
  min-width: 400px;
  max-width: 400px;
  transition: all 0.3s;
}

.sidebar-collapse.active {
  margin-left: -465px;
}

.content-area {
  width: 100%;
}

.transparentbtn {
  background: transparent !important;
}

.transparentbtn svg {
  font-size: 22px;
}

.heading-wrapper {
  display: flex;
  background: #0066cc;
  justify-content: space-between;
}

/* .action-dropdown {
  padding: 10px 0 !important;
} */
.action-dropdown .dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

.action-dropdown a.nav-link {
  padding: 10px 25px;
  color: #fff;
  cursor: unset;
}

.action-dropdown a.nav-link:hover {
  border: none;
}

.action-dropdown .dropdown-item {
  cursor: unset;
}

.action-dropdown .dropdown-item:active {
  background-color: #fff;
  color: #000;
}

.contentWrapperLibrary .card-header {
  background: transparent;
  padding: 0 12px;
}

._buybookcategory {
  font-size: 14px;
}

._buybookcategory .row div {
  padding: 10px;
  text-align: center;
}

._buybookcategory ._withbg {
  background: #ddd;
}

._buy-book-library img {
  height: 100%;
  /* min-height: 220px; */
  object-fit: contain;
  padding: 15px;
}

._buy-book-library .card-body {
  padding: 15px;
  text-align: center;
}

/* stripe css */

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px;
  max-width: 100%;
  padding: 18px 14px 7px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  height: 54px;
  border: 1px solid #0066cc;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input,
.StripeElement:hover {
  border-color: #000;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  /* box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px; */
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

/* ****************************************Home**************************************** */

.first-flex {
  width: 100%;
  text-align: center;
  color: white;
}

._banner_section {
  /* background: url('../webImages/final.png') #000 right no-repeat; */
  /* background-size: contain; */
  background-color: #000;
}

.banner-img {
  width: 100%;
  /* display: flex;
  align-items: center; */
  /* height: 85vh; */
}

.banner-img div {
  padding: 0 0 0 45px;
  /* width: 100%;
  min-width: 75%; */
}

.first-flex h3 {
  font-size: 24px;
  text-align: left;
  color: #fff !important;
  line-height: 32px;
}

/* .first-flex h1 {
  font-size: 60px !important;
  padding-top: 80px;
  color: #FFE599 !important;
  line-height:2
} */
.not-worry p,
.Becom-back-2 .row-b p {
  font-size: clamp(14px, 3vw, 24px) !important;
  line-height: clamp(24px, 4vw, 40px) !important;
}
.customize_textbook h1,
.becom-sec-back h1,
.Becom-sec5-back .not-worry h1,
.not-worry h1,

.not-worry h1,
/*.Becom-back-2 .row-b h1 {
  font-size: clamp(30px, 3vw, 50px);
  line-height: clamp(35px, 4vw, 135px)!important;
}*/

.Becom-back-2  h1 {
  color: #fff;
}

.first-flex h1 {
  padding-top: clamp(10px, 2vw, 80px) !important;
  color: #00cbff !important;
  line-height: 1.15 !important;
  transition: all 0.3s;
}
.second-flex {
  /*background-color: #ffffff;*/
  background-color: #1A1A1A !important;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
}

.second-flex h1 {
  /*color: #00a2ff;*/
  color: #000;
  text-align: left;
  font-weight: bold;
  padding-top: clamp(10px, 2.6vw, 50px) !important;
  font-size: clamp(30px, 3.41vw, 60px);
  line-height: clamp(35px, 4vw, 135px) !important;
}

.second-flex p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
  font-size: 22px;
  color: #fff;
}

._adapt_Section {
  height: 100%;
  padding: 60px 0;
}
.customize_textbook {
  background-color: #eaeaea;
  /* background-image: linear-gradient(180deg, #2f2f2f, #707070); */
  padding-top: 5%;
  padding-bottom: 5%;
}
.customize_textbook h1 {
  text-align: center;
  color: #0066cc;
}
.customize_textbook h4 {
  text-align: center;
  color: #000;
  text-align: center;
  padding-top: clamp(0px, 2vw, 20px);
  font-size: clamp(16px, 2vw, 30px);
  line-height: 1.6;
}

/*.adaptive_books_col h3 { text-align: center!important;}*/
.adaptive_books_col h1 {
  padding-top: 0px !important;
}

.grad-bg-Instructors {
  background: rgb(42, 74, 131);
  background: linear-gradient(135deg, #000000, #0066cc, #0077c9, #4d4d4d);
}

.grad-bg-sm {
  /* background: rgb(5,194,248);
  background: linear-gradient(295deg, rgba(5,194,248,1) 0%, rgba(0,10,10,1) 62%, rgba(24,43,76,1) 73%, rgba(40,71,127,1) 100%, rgba(0,212,255,1) 100%);
  */
  font-size: 22px !important;
  font-weight: normal;
  color: #fff !important;
  padding-top: 0px !important;
  height: 16% !important;
  text-align: center !important;
}
.grad-bg {
  background: rgb(5, 194, 248);
  background: linear-gradient(
    295deg,
    rgba(5, 194, 248, 1) 0%,
    rgba(0, 10, 10, 1) 62%,
    rgba(24, 43, 76, 1) 73%,
    rgba(40, 71, 127, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  font-size: 36px !important;
  font-weight: bold;
  color: #02b4e4 !important;
  padding-top: 0px !important;
  height: 16% !important;
}

.adaptive_books {
  /* background-color:#003A91;*/
  background: rgb(44, 78, 140);
  background: linear-gradient(135deg, #000000, #121212, #002366, #0066cc, #0077c9);
  /* background-image: linear-gradient(180deg, #2f2f2f, #707070); */
  padding-top: 25px;
}
.example {
  background: #fff !important;
  margin-bottom: 0.5em; /* Add margin to create space after the div */
}
.adaptive_books_col {
  width: 100%;
  padding-bottom: 105px;
}
.con-height-mt {
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: #f2f2f2;
}
.heading-co-blue {
  color: #00a1ff !important;
}

.adaptive_books_col h1 {
  color: #fff;
  text-align: center;
  /* padding-top: 4%; */
  /* font-size: 50px !important; */
  font-weight: bold;
  /* line-height: 1.5; */
  /*padding-top: clamp(10px,2.6vw, 50px)!important;*/
  font-size: clamp(30px, 3.41vw, 60px);
  line-height: clamp(35px, 4vw, 135px) !important;
}

.adaptive_books_col h3 {
  color: #fff;
  text-align: center !important;
  padding-top: 4%;
  font-size: 30px;
  line-height: 1.6;
}

.adaptive_books_white {
  background-color: white;
  width: 75%;
  margin: 0 auto;
  margin-top: 4%;
  height: 400px;
  text-align: center;
}

.adaptive_books_white p {
  padding-top: 200px;
}

.interactive_graphs {
  width: 100%;
  padding: 10% 0% 10% 0%;
  background-color: #eaeaea;
}

.interactive_graphs h1 {
  color: #00a1ff;
  text-align: center;
  /* font-size: 50px !important; */
  font-weight: bold;
  /* line-height:2; */
  /* padding-top: clamp(10px,2.6vw, 50px)!important; */
  font-size: clamp(30px, 3.41vw, 48px);
  line-height: clamp(35px, 4vw, 135px) !important;
  margin-bottom: 2%;
}

.interactive_graphs p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
  /* font-family: 'Ubuntu', sans-serif;*/
  text-align: left;
  color: #000;
  font-size: 22px;
  font-weight: 400;
  padding-bottom: 3%;

  /* font-size: 27px; */
  /* padding-top: 45px; */
  /* margin-bottom: 50px;*/
  /* line-height:45px; */
  /* padding-top: clamp(0px,2.6vw, 45px)!important;
  font-size: clamp(16px, 3.41vw, 24px);
  line-height: clamp(30px, 3.4vw, 50px)!important;*/
}

img.img-fluid.graph-img {
  width: 100%;
}

.students p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
  /* font-size: 30px; */
  /* line-height:1.7; */
  margin-bottom: 0px;
  color: #fff;
}

.flex-button {
  background-color: #1e90ff;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  position: relative;
  display: block;
  top: 75px;
  left: 169px;
  font-size: 16px;
  margin: 24px 2px;
}

.for_instructors_second_BG {
  background: rgb(42, 74, 131);
  background: radial-gradient(
    circle,
    rgba(42, 74, 131, 1) 0%,
    rgba(124, 157, 213, 1) 0%,
    rgba(50, 81, 134, 1) 100%,
    rgba(42, 74, 130, 1) 100%
  );
}

.for_instructors_second {
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: #032340;
  background-image: linear-gradient(135deg, #000000, #121212, #002366, #0066cc, #0077c9);
}

.for_instructors_second h1 {
  color: #fff;
  text-align: center;
  padding-top: clamp(10px, 2.6vw, 50px) !important;
  font-size: clamp(30px, 3.41vw, 48px);
  line-height: clamp(35px, 4vw, 135px) !important;
  /* padding-top: 50px; */
  /* font-size: 66px !important; */
  /* line-height:2; */
  font-weight: bold;
}

.for_instructors_second h4 {
  font-size: 2rem;
  line-height: 2;
  color: #fff;
  text-align: center;
  padding-top: 20px;
}

.for_instructors_sec_BG {
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: rgb(44, 78, 140);
  background: linear-gradient(135deg, #000000, #121212, #002366, #0066cc, #0077c9);
}

.for_instructors_sec_BG h1 {
  color: #fff;
  text-align: center;
  padding-top: 5%;
}

.for_instructors {
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: rgb(42, 74, 131);
  background: linear-gradient(135deg, #000000, #0066cc, #0077c9);
}

.for_instructors h1 {
  color: #fff;
  text-align: center;
  padding-top: clamp(10px, 2.6vw, 50px) !important;
  font-size: clamp(30px, 3.41vw, 60px);
  line-height: clamp(35px, 4vw, 135px) !important;
  /* padding-top: 50px; */
  /* font-size: 66px !important; */
  /* line-height:2; */
  font-weight: bold;
}

.for_instructors h4 {
  font-size: 2rem;
  line-height: 2;
  color: #fff;
  text-align: center;
  padding-top: 20px;
}

ul.ms-auto.instructor_ul {
  color: #fff;
  list-style-type: none;
  font-size: 20px;
  padding-top: 3%;
  padding-left: 37%;
}
.color-pink {
  color: rgb(255, 0, 191);
}
.instructors_white {
  background-color: #000;
  border: 0px solid #ccc;
  width: 75%;
  margin: 4% auto 20px;
  height: 400px;
}

.instructors_white p {
  padding-top: 20% !important;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
}
.current_job_openings {
  background-image: url(../webImages/current_job_openings.jpg);
  background-position: center center;
  background-size: cover;
  padding: 200px 20px;
  text-align: center;
  color: #fff;
}

.img-alignment img {
  text-align: right;
  float: right;
}

.pad-p {
  padding: 0px 15% 0px 0px;
  text-align: left;
}

.mission {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.507)), url('../webImages/mission.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50vh;
  display: flex;
  align-items: center;
  font-size: 30px;
  line-height: 50px;
  padding-bottom: 50px;
  padding-top: 25px;
}

.mission h1 {
  color: #1e90ff;
  text-align: center;
  /* font-size: 66px !important; */
  font-weight: bold;
  /* line-height:2 */
  padding-top: clamp(10px, 2.6vw, 50px) !important;
  font-size: clamp(30px, 3.41vw, 60px);
  line-height: clamp(35px, 4vw, 135px) !important;
}

.students p,
.mission p {
  padding-top: clamp(0px, 2vw, 10px) !important;
  /*font-size: clamp(14px, 2vw, 24px);*/
  line-height: clamp(25px, 2.5vw, 50px) !important;
}
.mission p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
  /*font-family: 'Ubuntu', sans-serif;*/
  text-align: center;
  /* font-size: 27px; */
  /* padding-top: 20px; */

  color: #fff;
  /* line-height:2 */
}

._grey_ul {
  /* background: lightgrey; */
  padding: 8px;
}

._white_list {
  user-select: none;
  padding: 12px;
  margin: 0px 0px 6px;
  text-align: right;
  background: white;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgb(20 20 20 / 72%), rgb(0 0 0 / 55%));
  background: -webkit-radial-gradient(rgb(20 20 20 / 72%), rgb(0 0 0 / 55%));
}

.iframeLoading:before {
  background: radial-gradient(rgb(20 20 20 / 0%), rgb(0 0 0 / 0%));
  background: -webkit-radial-gradient(rgb(20 20 20 / 0%), rgb(0 0 0 / 0%));
}

/* ****************************************Team / Vivek**************************************** */
.team_back {
  background-image: url(../webImages/team_banner.jpg);
  background-position: center center;
  background-size: cover;
  padding: 200px 20px;
  text-align: center;
  color: #fff;
}

.team_back hr {
  color: #fff;
  width: 250px;
  margin: auto;
  margin-bottom: 15px;
  height: 2px;
}

.team {
  margin-top: 50px;
}

.team h1 {
  text-align: center;
  padding-bottom: 50px;
}

.col-6.team_head h4 {
  padding-right: 50px;
  padding-bottom: 10px;
}

.col-6.team_head h5 {
  font-size: 23px !important;
  padding-right: 130px;
}

.team_head p {
  text-align: justify;
  width: 100%;
  padding-top: 10px;
  font-size: 20px;
  padding-bottom: 50px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
}

img.img-fluid.team_img {
  /* width: 45%; */
  height: 250px;
  text-align: center;
}

.team_head_2 {
  /* background: aquamarine; */
  margin-left: 50px;
  margin-bottom: 50px;
}

.team_head_2 p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
}

.team_head_1 {
  /* background: aquamarine; */
  margin-right: 50px;
  margin-bottom: 50px;
}

.team_head_1 h4 {
  padding-bottom: 10px;
}

.team_head_1 h5 {
  font-size: 23px;
  /* padding-right: 130px; */
}

img.img-fluid.team_img-2 {
  height: 250px;
}

.team_head_2 h4 {
  padding-bottom: 10px;
}

.team_head_2 h5 {
  font-size: 23px;
  /* padding-right: 130px; */
}

.team_head_2 p {
  text-align: justify;
  width: 100%;
  font-size: 20px;
  padding-top: 10px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url('../webImages/hamburger.png') !important;
}

._file_upload_design label {
  background-color: #fff;
  padding: 3px 10px;
  color: #0066cc;
  border-radius: 10px;
  transition: 0.5s;
  /* border: 2px solid #000000; */
  font-size: 14px;
  margin: 5px 0;
}

._file_upload_design label:hover {
  background-color: #000;
  color: #fff;
  border-color: #fff;
}

._file_upload_design label input[type='file'] {
  display: none;
}

.default_white_btn:hover {
  background-color: #000;
  color: #fff;
  border-color: #fff;
}

._html_content_section {
  margin: 5%;
}

.yes_no_inputs {
  margin-top: -4px;
}

.becapara {
  margin-top: -20px;
}

/* ----------------------------------------------become-an-author-new----------------------------------- */
.Becom-back-2 {
  background-color: #000000;
  overflow: hidden;
  height: 600px;
}

.Becom-back-2 .row-b {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 70px 0px 70px;
}

.Becom-back-2 .row-b h1 {
  font-size: 48px;
  color: #00cbff !important;
  margin-bottom: 30px;
  font-weight: 900;
}

.Becom-back-2 .row-b p {
  font-size: 22px !important;
  color: #fff;
  width: 52%;
  margin-bottom: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
}

.Becom-back-2 .row-b p span {
  color: #000;
}

.Becom-back-2 .row-b .ban-img {
  max-width: 550px !important;
  /* max-height: 600px; */
  margin-bottom: 0px;
  /*margin-top: -100px !important;*/
}

.Becom-back-2 .row-b .btn {
  background-color: #4285f4;
  color: white;
  padding: 7px 25px;
  border-radius: 0px;
}

.not-worry {
  padding: 50px 0;
}

.not-worry h1 {
  /* font-size: 50px; */
  color: #00a2ff;
  margin-bottom: 30px;
  font-weight: 900;
  text-align: end;
}

.not-worry span {
  font-size: 25px;
  margin-top: 25px;
  padding-left: 30px;
}

.not-worry .icon {
  margin-top: 7px;
  font-size: 21px;
  margin-right: 20px;
}

.Becom-sec3-back {
  /* background-color:#003A91;*/
  padding-top: 5%;
  padding-bottom: 5%;
  background: #002366;
  background: linear-gradient(135deg, #000000, #121212, #002366, #0066cc, #0077c9);
}

.Becom-sec3-back h1 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
  font-size: 48px;
}

.Becom-sec3-back p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
  font-size: 22px;
}

.Becom-sec3-back img {
  margin-top: -60px;
}

.Becom-sec3-back h1 {
  text-align: left;
  width: 80%;
  color: #fff;
}

.Becom-sec3-back span {
  color: #fff;
  padding-left: 0px;
}

.not-worry p {
  /* font-size: 25px; */
  /* margin-top: 25px; */
  padding-left: 30px;
}

.not-worry .sec-4 p {
  margin-top: 40px;
  padding-left: 0px;
}

.Becom-sec5-back {
  padding-top: 10%;
  padding-bottom: 10%;
  background: #002366;
  background: linear-gradient(135deg, #000000, #121212, #002366, #0066cc, #0077c9);
}

.Becom-sec5-back h1,
.Becom-sec5-back h3 {
  color: #fff;
}

.Becom-sec5-back p {
  color: #fff;
}

.Becom-sec5-back .not-worry {
  padding: 50px 0 0 0;
}

.Becom-sec5-back .not-worry h1 {
  text-align: left;
}

.Becom-sec5-back .not-worry p {
  color: #fff;
  padding-left: 0px;
}

.Becom-sec5-back .sec-5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.becom-sec-back .sec-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.becom-sec-back h1 {
  /* font-size: 50px; */
  text-align: center;
  padding: 50px 0;
  font-weight: bold;
  color: #000;
}

.becom-sec-back .form-control {
  border-radius: 0px;
  margin-top: 0px;
}

.becom-sec-back .form-select {
  border-radius: 0px;
}

.becom-sec-back img {
  margin: auto;
}

.answer-list {
  display: flex;
  align-items: center;
}

.Library .card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.banner-img div .f-size {
  font-size: 1.4rem !important;
}

.P-Font p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
}

.adaptive_books_col .color-b {
  color: #ffffff;
}

.university-logos img {
  max-width: 100px; /* Adjust the size as needed */
  /* min-height: 100px; */
  margin: 20px auto; /* Centers the image in the column */
}

@media (min-width: 768px) {
  .university-logos img {
    max-width: 150px; /* Larger size for larger screens */
  }
}

.proof {
  background-color: #f2f2f2;
  padding-bottom: 20px;
  padding-top: 10px;
  margin-top: 5px;
}

.footnotes-end-of-document {
  font-size: 16px;
}

figure.image figcaption {
  background-color: transparent !important; /* Override background to be transparent */
}
