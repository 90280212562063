.language-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px;
}

@media screen and (max-width: 768px) {
  .language-dropdown {
    margin: 20px 0;
  }
}

.selected-language {
  background-color: #f0f0f0;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
}

.language-list {
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  top: 50px;
  background-color: #fff;
  width: 140px;
  color: #000;
  transition: all 0.2s ease-in-out;
}

.language-list li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.language-dropdown:hover .language-list {
  visibility: visible;
  opacity: 1;
}
.flag {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
