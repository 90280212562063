* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
@media only screen and (max-width: 1200px) {
  .mainlesson {
    width: 100%;
    /* overflow: auto; */
    padding: 0 0px;
  }
  .lessons_lt > div:last-child {
    overflow: auto;
    max-width: 100%;
  }
  .lessons_lt .bookContent {
    width: 100%;
    /* min-width: 1200px;
    overflow: auto; */
  }
  .lessons_lt .bookContent.w-75 {
    width: 100% !important;
  }
  /* .mission h1,
    .for_instructors h1,
    .interactive_graphs h1,
    .adaptive_books_col h1,
    .second-flex h1 {
        font-size: 50px !important;
    } */
  /* .mission p {
        line-height: 24px;
    } */
}

@media only screen and (max-width: 993px) {
  .mission {
    height: auto;
  }
  /* .mission h1,
    .for_instructors h1,
    .interactive_graphs h1,
    .adaptive_books_col h1,
    .second-flex h1 {
        font-size: 40px !important;
        line-height: 45px;
    } */
  .first-flex h1 {
    /* line-height: 40px;
        font-size: 30px !important; */
    /* padding-top: 10px; */
  }
  .first-flex h3 {
    font-size: 16px !important;
    line-height: 24px;
  }
  .for_instructors h4,
  .adaptive_books_col h3 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .adaptive_books_col {
    padding-bottom: 20px;
  }
  .media iframe.embed-responsive-item {
    width: 100% !important;
    height: 212px !important;
  }
  .interactive_graphs p {
    width: 100% !important;
    text-align: center;
    line-height: 28px;
  }
  ._adapt_Section {
    padding: 45px 0 0;
  }
  ._adabptSection_title h1 {
    font-size: 32px !important;
  }
  .instructors_white {
    display: flex;
    height: 320px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .instructors_white p {
    padding-top: 0 !important;
  }
  .F-box {
    margin-bottom: 40px;
  }
  section._search_wrapper .row.justify-content-center {
    justify-content: inherit !important;
  }
  ul.ms-auto.instructor_ul li {
    margin-bottom: 14px;
  }
  .MuiFormControl-root.me-4.css-14r17bb-MuiFormControl-root {
    width: 100%;
  }
  ._search_wrapper {
    overflow: hidden;
  }
  .default-black-btn {
    margin-top: 44px;
  }
  section._search_wrapper.search_wrapper2 {
    padding-top: 0;
  }
  .adaptive_books h1 {
    margin-top: 15px !important;
  }
  .serchclass.d-flex.justify-content-center {
    display: initial !important;
  }
  section._search_wrapper .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .grad-bg-sm {
    height: auto;
    margin-bottom: 45px;
  }
  .second-flex.con-height-mt h1.pl-4.heading-co-blue.mt-5.mb-5.pb-5 {
    padding: 0 !important;
  }
  .media {
    width: 100% !important;
  }
  .for_instructors,
  .interactive_graphs {
    padding: 14px 10px;
  }
  .for_instructors h1 {
    padding-top: 45px;
  }

  .interactive_graphs p {
    /* padding-top: 10px; */
    margin-bottom: 10px;
    /* line-height: 24px; */
  }
  /* .mission h1,
    .for_instructors h1,
    .interactive_graphs h1,
    .adaptive_books_col h1,
    .second-flex h1 {
        font-size:24px !important;
        line-height:30px;
    } */
  .for_instructors h4,
  .adaptive_books_col h3 {
    font-size: 20px;
    line-height: 24px;
  }

  .Becom-back-2 .row-b p span {
    font-size: 20px !important;
  }

  .css-1mo76i-MuiAutocomplete-root {
    width: 300px;
    margin-bottom: 20px;
  }

  .css-18tjb19-MuiStack-root {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .css-lr1r4n-MuiStack-root {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .answer-list {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .header-section .navbar-brand {
    margin-left: 0px;
  }

  a.nav-link {
    padding: 9px 0 9px 0px;
  }

  .white-btn {
    width: 40%;
  }

  .navbar {
    padding: 0px !important;
  }

  .mob-margin {
    margin-top: 20px;
  }

  .H-slider-text {
    display: block;
    text-align: center;
  }

  .H-slider-text hr {
    margin: auto;
  }

  .H-slider-text p {
    width: 100%;
    padding: 8px 0 8px 0;
  }

  .H-slider-text h1 {
    font-size: 36px;
    line-height: 46px;
  }

  .shape {
    padding: 80px 0;
    margin-top: 10px;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    font-size: 20px;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    font-size: 20px;
  }

  .H-slider-text .btn {
    padding: 3px 45px;
    font-size: 15px;
  }

  ._banner_section {
    background-position: right bottom;
  }

  .first-flex h3 {
    text-align: center;
    font-size: 18px;
    margin-top: 36px;
    margin-top: 30px;
  }

  .first-flex h1 {
    /* line-height: 40px;
        font-size: 30px !important; */
    /* padding-top: 10px; */
  }

  .second-flex img {
    width: 100%;
  }

  .students {
    padding-top: 25px;
    text-align: center;
  }

  .flex-button {
    top: 0px;
    left: 0px;
    font-size: 20px;
    margin: 15px 0px;
    width: 100%;
  }
  ul.ms-auto.instructor_ul {
    width: 100%;
    padding-left: 0px;
    margin-bottom: 30px;
  }
  .footer p {
    margin-bottom: 12px;
  }
  .banner-img div {
    padding: 0px;
  }

  .H-cont-box-text .btn {
    margin-bottom: 30px;
  }

  .custombg {
    padding: 20px 10px 60px 10px !important;
  }
  .mobile {
    padding: 0 !important;
  }
  .mobile h3 {
    margin-bottom: 42px;
  }
  div#becaome_author .p-4.m-4.card {
    margin: 0 !important;
  }
  .category-image-section .carousel-indicators {
    bottom: -46px;
  }
  .category-image-section .gif img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .category-image-section h1 {
    font-size: 32px !important;
  }
  .custombg:nth-child(even) .carousel-caption-custom {
    padding-right: 0;
  }

  .H-sec2-text {
    margin-top: 22px;
  }

  .category-image-section hr {
    margin-bottom: 0px;
  }

  .H-cont-img {
    padding: 40px 20px 20px 0px;
  }

  .top-home-back .css-jxt19t-MuiInputBase-root-MuiOutlinedInput-root {
    width: 100%;
  }

  .top-home-back {
    padding: 50px 10px;
  }

  .carousel-caption-custom {
    text-align: center;
  }

  .carousel-caption-custom {
    padding: 0px;
  }

  .css-10xp3ry {
    width: 28px;
    height: 28px;
    margin-right: 15px;
  }

  .default-btn {
    margin-top: 0px;
    font-size: 0.9rem;
    padding: 7px 9px;
  }

  .team h1 {
    text-align: center;
    padding-bottom: 30px;
  }

  .team_head p {
    padding-bottom: 0px;
    line-height: 25px;
    text-align: center;
  }

  .team_head_2 p {
    text-align: center;
    line-height: 25px;
  }

  .pagination-container {
    justify-content: center;
    /* flex-direction: column; */
    overflow-x: scroll;
  }

  .our_blog .categoreis .css-1ws6yl5-MuiInputBase-root-MuiOutlinedInput-root {
    width: 100%;
  }

  .css-u47x3v-MuiInputBase-root-MuiOutlinedInput-root {
    width: 100%;
  }

  .our_blog .default-btn svg {
    font-size: 20px !important;
  }

  .our_blog .categoreis {
    margin-bottom: 50px;
  }

  .text-end {
    padding-bottom: 15px;
  }

  .forum .F-box {
    padding: 10px;
  }

  /* h1 {
    font-size: 1.5rem !important;
  } */

  h5 {
    font-size: 1.2rem !important;
  }

  .form-box {
    padding: 40px 7px;
    text-align: center;
  }

  .contact-back,
  .team_back {
    padding: 35px 20px;
  }

  .profile-card {
    width: 90%;
    margin-top: -18px;
    padding: 25px 0;
  }

  .profile-content-area {
    padding: 20px 20px;
  }

  .team_head_1 {
    margin-right: 0px;
    padding-bottom: 40px;
    text-align: center;
  }

  .team_head_1 img {
    margin: auto;
  }

  .team_head_2 img {
    margin: auto;
  }

  .team_head_2 {
    margin-left: 0px;
    padding-bottom: 40px;
    text-align: center;
  }

  .team_head_2 h5 {
    padding-right: 0px;
  }

  .author_head_sec {
    padding: 0px 0px !important;
    width: 100% !important;
  }

  .author_head_sec {
    overflow: hidden;
    text-align: center;
  }

  .author_head_left {
    padding: 20px 15px;
  }

  .author_head_right {
    padding: 15px;
    text-align: -webkit-center;
  }

  .educative_sec {
    padding: 0px 15px;
  }

  .educative_rows h2 {
    margin-top: 50px;
  }

  .interactivity div {
    padding: 30px 15px;
    text-align: -webkit-center;
  }

  .educative_rows {
    padding: 0px 0px 30px 0px;
    align-items: start;
    text-align: -webkit-center;
  }

  .educative_rows p {
    margin-bottom: 15px;
  }

  .testimonial_cl {
    text-align: center;
    padding: 25px 40px !important;
  }

  .faq_sec {
    padding: 30px 15px 0px 15px !important;
    width: 100% !important;
    text-align: center;
  }

  .form_sec h1 {
    padding: 30px 15px 0px 15px !important;
  }

  .testimonial_sec {
    text-align: center;
    padding: 20px 15px !important;
  }

  .form_sec {
    padding: 0px 15px;
    text-align: center;
  }

  .testimonial_cl {
    text-align: center;
    padding: 25px 10px !important;
  }

  .form_cl {
    padding: 0px;
    text-align: left;
  }

  .interactivity div {
    padding: 15px 15px;
  }

  .H-sec2-text {
    margin-top: 50px;
  }

  .H-cont-box-text {
    text-align: center;
  }

  .css-32yp37-MuiFormControl-root-MuiTextField-root {
    margin-bottom: 15px;
  }

  .ck.ck-editor {
    margin-top: 20px !important;
  }

  .css-3ay1vu {
    width: 100%;
  }

  .listing-wrapper {
    width: 78%;
  }

  .rc-anchor-normal .rc-anchor-pt {
    right: 68px;
  }

  .rc-anchor-logo-portrait {
    width: 58px;
    margin: 10px 0 0 0px !important;
  }

  .rc-anchor-normal-footer {
    width: 45px !important;
  }

  .rc-anchor-normal {
    width: 230px !important;
  }

  input,
  .StripeElement {
    margin: 4px 0 20px 0;
  }

  .Library hr {
    width: 100%;
  }

  .Library .row {
    margin-top: 20px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 15%);
    padding: 20px 0;
  }

  .Library-back .box {
    padding: 50px 0px 0px 0px;
  }

  .Library-back {
    padding: 0px 0px 0px 0px;
  }

  .addbook .avatar-upload .avatar-preview > img {
    object-fit: none;
  }

  .inputContainer {
    margin-top: 17px;
  }

  .css-d3ri6l-MuiStack-root,
  .css-8wjn80-MuiStack-root {
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .css-4w5dxs {
    width: 100%;
  }

  .css-50exmm-MuiTypography-root {
    text-align: center;
  }

  .css-jpln7h-MuiTabs-scroller {
    overflow: scroll !important;
  }

  /* -------------------------------------Becom an auther---------------------------- */
  .Becom-back-2 .row-b {
    padding: 31px 15px 0px 15px;
    text-align: center;
  }

  .Becom-back-2 .row-b p {
    width: 100%;
    margin-bottom: 30px;
    font-size: 20px !important;
  }

  /* .Becom-back-2 .row-b h1 {
        font-size: 45px !important;
    } */

  .Becom-back-2 .row-b .ban-img {
    width: 65%;
    margin: auto;
    margin-top: 25px;
  }

  .not-worry {
    padding: 50px 15px;
  }

  .not-worry h1 {
    text-align: center;
    margin-bottom: 15px;
  }

  .not-worry .icon {
    margin-right: 0px;
  }

  .not-worry span {
    padding-left: 0px;
  }

  .Becom-sec3-back h1 {
    width: 100%;
  }

  .Becom-sec3-back img {
    margin-top: 30px;
  }

  .not-worry .sec-4 p {
    text-align: center;
    margin-top: 0px;
  }

  .Becom-sec5-back .not-worry h1 {
    text-align: center;
  }

  .Becom-sec5-back .not-worry p {
    margin-bottom: 20px;
    text-align: center;
  }
}

/* ---------------------------------------------------------------------------------------------------- */

@media only screen and (min-width: 768px) {
  .css-lr1r4n-MuiStack-root {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .css-18tjb19-MuiStack-root {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .pagination-container {
    justify-content: end;
    flex-direction: row;
  }

  .card-wrap img {
    height: 168px;
  }

  .white-btn {
    width: 25%;
  }

  .navbar {
    padding: 0px !important;
  }

  .answer-list p {
    text-align: left;
  }

  .Becom-back-2 .row-b {
    text-align: center;
  }

  .Becom-back-2 .row-b p {
    width: 100%;
  }

  .Becom-back-2 .row-b .ban-img {
    padding-top: 30px;
    width: 65%;
    margin: auto;
  }

  .not-worry h1 {
    /* font-size: 45px; */
    text-align: center;
  }

  .not-worry p {
    /* font-size: 15px; */
    text-align: left;
  }

  .Becom-sec3-back h1 {
    width: 100%;
  }

  .Becom-sec3-back img {
    margin-top: 0px;
  }

  .not-worry .sec-4 p {
    text-align: center;
  }

  .Becom-sec5-back .not-worry p {
    text-align: center;
  }

  .Library-back .box {
    padding: 50px 0px 0px 0px;
  }

  .Library-back {
    padding: 0px 0px 0px 0px;
  }

  .forum-box p {
    text-align: left;
  }

  .ck.ck-editor {
    margin-top: 15px !important;
  }

  .Library .row {
    padding: 50px 0;
  }

  .css-32yp37-MuiFormControl-root-MuiTextField-root {
    margin-bottom: 15px;
  }

  .H-slider-text h1 {
    line-height: 30px;
    font-size: 30px;
  }

  .H-slider-text {
    display: block;
  }

  .first-flex h3 {
    text-align: center;
    margin-bottom: 35px;
    margin-top: 40px;
  }

  .H-sec2-text {
    margin-top: 10px;
  }

  .first-flex h1 {
    /* line-height: 45px; */
    /* padding-top: 25px; */
  }

  .students {
    padding-top: 50px;
  }
  /* 
    .students p {
        font-size: 24px;
    } */

  ul.ms-auto.instructor_ul {
    padding-left: 20%;
  }

  .footer p {
    font-family: 'Montserrat', sans-serif;
  }

  .our_blog .categoreis .css-1ws6yl5-MuiInputBase-root-MuiOutlinedInput-root {
    width: 100%;
  }

  .our_blog .default-btn svg {
    font-size: 23px !important;
  }

  .our_blog .categoreis {
    margin-bottom: 50px;
  }

  .default-btn {
    margin-top: 0px;
  }

  .forum .F-box {
    padding: 25px;
  }

  .student-para {
    position: relative;
  }

  .contact-back,
  .about-back,
  .team_back {
    padding: 50px 20px;
  }

  .team_head p {
    text-align: justify;
  }

  .faq_wd h1 {
    margin-bottom: 0px;
  }

  .author_head_right {
    padding: 0px;
  }

  .author_head_right img {
    margin: auto;
  }

  .author_head_sec h1 {
    font-size: 36px;
    line-height: 36px;
  }

  .author_head_sec {
    padding: 40px 0px !important;
    width: 100% !important;
    text-align: center;
  }

  .interactivity div {
    padding: 0px;
    color: #000;
    text-align: center;
  }

  .educative_rows {
    padding: 25px 0;
    align-items: center;
  }

  .interactivity img {
    margin: auto;
    margin-bottom: 25px;
  }

  .author_head_left {
    padding: 25px 0;
  }

  .educative_rows .magic {
    padding: 25px 0 0px 0;
  }

  .educative_rows .magic h3,
  p {
    /* text-align: center; */
  }

  .educative_rows .focus {
    /* text-align: center; */
  }

  .testimonial_sec {
    text-align: center;
    padding: 50px 0 !important;
  }

  .testimonial_cl {
    text-align: center;
    padding: 50px 15px 0px 15px !important;
  }

  .carousel-caption-custom {
    text-align: left;
  }

  .carousel-caption-custom p {
    text-align: left;
  }

  .H-cont-box-text {
    margin: 30px 0 50px 0;
    text-align: center;
  }

  .profile-content-area p {
    text-align: left;
  }

  /* .about-back h1 {
        padding-bottom: 30px;
    } */

  .H-sec2-text {
    margin-top: 40px;
  }

  .H-cont-img {
    padding: 20px 20px 20px 0px;
  }

  .category-image-section .gif img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .mob-margin {
    margin-top: 20px;
  }

  .listing-wrapper {
    width: 100%;
  }

  .addbook .avatar-upload .avatar-preview > img {
    object-fit: none;
  }

  .css-d3ri6l-MuiStack-root {
    direction: row;
    align-items: center;
    justify-content: space-between;
  }

  ._banner_section .img-box {
    display: flex;
    justify-content: end;
    padding-right: 0;
  }
}

@media only screen and (min-width: 993px) {
  .white-btn {
    width: auto;
  }

  .Library-back {
    padding: 50px 0;
  }

  .navbar {
    padding: 0 37px !important;
  }

  /* Styles for responsive mode */
  @media (max-width: 768px) {
    .nave-left {
      width: 100%; /* Make it full screen width */
      background-color: #000000;
      padding: 5px 50px 50px 10px;
      margin-bottom: 50px;
      min-height: 100vh; /* 100vh sets the height to be 100% of the viewport height */
      transition: min-height 0.5s;
    }
  }

  .card-wrap img {
    height: 174px;
  }

  .first-flex h3 {
    text-align: left;
    line-height: 32px;
  }

  .first-flex {
    text-align: left;
  }

  /* .first-flex h1 {
        width: 100%;
        line-height: 65px;
        text-align: left;
        font-size: 41px !important;
    } */

  .students {
    padding-top: 100px;
  }

  .students {
    font-size: 21px;
  }

  .contact-back,
  .about-back,
  .team_back {
    padding: 100px 20px;
  }

  .author_head_sec h1 {
    font-size: 36px;
    line-height: 36px;
  }

  .author_head_right {
    padding: 0px 5px;
  }

  .author_head_left,
  .interactivity div {
    text-align: left;
  }

  .educative_rows .magic h3,
  p {
    text-align: left;
  }

  .educative_rows .focus {
    text-align: left;
  }

  .profile-content-area p {
    text-align: left;
    word-wrap: break-word;
  }

  .team_back p {
    color: #fff;
    text-align: center;
    font-size: 20px;
  }

  .H-sec2-text p {
    text-align: center;
  }

  .H-cont-box-text {
    text-align: left;
  }

  .H-sec2-text {
    margin-top: 40px;
  }

  ul.ms-auto.instructor_ul {
    padding-left: 27%;
  }

  .H-cont-img {
    padding: 20px 20px 40px 0px;
  }

  .testimonial_cl p {
    text-align: center !important;
    margin-top: 15px;
  }

  .category-image-section .gif img {
    height: 262px;
  }

  .banner-img div {
    padding: 0px 0 0 118px;
  }

  .future-text div {
    padding: 0px 0 0 50px;
  }

  ._banner_section .row-h {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Becom-back-2 .row-b {
    text-align: left;
  }

  .Becom-back-2 .row-b p {
    width: 75%;
  }

  .not-worry h1 {
    text-align: right;
  }

  .not-worry p {
    /* font-size: 18px; */
  }

  .Becom-sec3-back h1 {
    text-align: left;
  }

  .not-worry .sec-4 p,
  .Becom-sec5-back .not-worry p {
    text-align: left;
  }

  .Becom-sec3-back img {
    margin-top: -60px;
  }

  .not-worry .sec-4 p {
    padding-left: 30px;
  }

  .Becom-back-2 .row-b .ban-img {
    padding-top: 60px;
    width: 100%;
    margin: auto;
    border: 0px solid #fff;
  }

  /* .Becom-back-2 .row-b h1 {
        font-size: 45px;
    } */
}

@media only screen and (min-width: 1200px) {
  /* .first-flex h1 {
        line-height: 80px;
        font-size: 48px !important;
    } */

  .card-wrap img {
    height: 250px;
  }

  .Becom-sec3-back h1 {
    width: 100% !important;
    font-size: 50px;
  }

  .not-worry .sec-4 p {
    padding-left: 71px;
  }

  .not-worry p {
    /* font-size: 25px; */
  }

  .category-image-section .gif img {
    height: 300px;
  }

  ul.ms-auto.instructor_ul {
    padding-left: 33%;
  }

  .css-lr1r4n-MuiStack-root {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* .Becom-back-2 .row-b h1 {
        font-size: 50px;
    } */

  .Becom-back-2 .row-b p {
    width: 95%;
    font-size: 25px;
  }
}

/*responsive css -------------------------------------------------------- */
@media screen and (max-width: 993px) and (min-width: 360px) {
  .img-alignment img {
    text-align: center !important;
    width: 100% !important;
  }
  .text-img-rig-cen {
    width: 100% !important;
    text-align: center !important;
    padding: 5px;
  }
  .rem-mt {
    margin-top: 0px !important;
    text-align: center !important;
  }
  .pad-p {
    padding: 2px 10% 0px 10%;
    text-align: center !important;
  }

  .adaptive_books_col .w-50 {
    color: #fff;
    text-align: center;
    /* padding-top: 4%; */
    /* font-size: 50px !important; */
    font-weight: bold;
    /* line-height: 1.5; */
    /* padding-top: clamp(10px,2.6vw, 50px)!important; */
    font-size: clamp(30px, 3.41vw, 60px);
    line-height: clamp(35px, 4vw, 135px) !important;
    width: 100% !important;
  }

  .interactive_graphs h1 {
    color: #135cbb;
    text-align: center;
    /* font-size: 50px !important; */
    font-weight: bold;
    /* line-height: 2; */
    /* padding-top: clamp(10px,2.6vw, 50px)!important; */
    font-size: clamp(30px, 3.41vw, 60px);
    line-height: clamp(35px, 4vw, 135px) !important;
    margin-bottom: 2%;
    padding-top: 3%;
  }

  .topbgimg {
    background-image: url(../webImages/top_g.png);
    background-repeat: no-repeat;
    background-position: top left;
    width: 274px;
    height: 133px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-size: 45% 45%;
  }

  .rightimg {
    background-image: url(../webImages/right_g.png);
    background-repeat: no-repeat;
    background-position: top right;
    width: 100px;
    height: 281px;
    position: absolute;
    right: 0;
    z-index: 9999;
    background-size: 45% 45%;
  }

  .rightblueimg {
    background-image: url(../webImages/rightblue_g.png);
    background-repeat: no-repeat;
    background-position: top right;
    width: 79px;
    height: 301px;
    position: absolute;
    right: 0;
    z-index: 9999;
    background-size: 45% 45%;
  }

  .leftimg {
    background-image: url(../webImages/left_g.png);
    background-repeat: no-repeat;
    background-position: top left;
    width: 86px;
    height: 343px;
    position: absolute;
    top: 50;
    left: 0;
    z-index: 9999;
    background-size: 45% 45%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 994px) {
  .topbgimg {
    background-image: url(../webImages/top_g.png);
    background-repeat: no-repeat;
    background-position: top left;
    width: 274px;
    height: 133px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-size: 20% 20%;
  }

  .rightimg {
    background-image: url(../webImages/right_g.png);
    background-repeat: no-repeat;
    background-position: top right;
    width: 100px;
    height: 281px;
    position: absolute;
    right: 0;
    z-index: 9999;
    background-size: 60% 60%;
  }

  .rightblueimg {
    background-image: url(../webImages/rightblue_g.png);
    background-repeat: no-repeat;
    background-position: top right;
    width: 79px;
    height: 301px;
    position: absolute;
    right: 0;
    z-index: 9999;
    background-size: 60% 60%;
  }

  .leftimg {
    background-image: url(../webImages/left_g.png);
    background-repeat: no-repeat;
    background-position: top left;
    width: 86px;
    height: 343px;
    position: absolute;
    top: 50;
    left: 0;
    z-index: 9999;
    background-size: 60% 60%;
  }
}

custom-mathjax.css .MathJax .msup sup {
  font-size: 80%; /* Adjust the percentage as needed */
}

/* I have added to better use the width of the screen */
@media only screen and (max-width: 993px) {
  .w-75 {
    width: 90% !important;
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
