/* .MuiFormControl-root input {
  padding: 10.5px 14px !important;
} */

.selectpadding div {
  padding: 10.5px 14px !important;
}

.customSelect div[class*='-control'] {
  border-radius: 8px;
}

.customSelect div[class*='-placeholder'] {
  margin-top: -4px;
}

.customSelect div[class*='-Input'],
.customSelect div[class*='-control'],
.customSelect div[class*='-ValueContainer'] {
  height: 53px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.socialBtn div[class*='-MuiStack-root'] {
  display: block !important;
}

.socialBtn div[class*='-MuiStack-root'] > * {
  display: block;
  width: 100%;
  margin: 0px 0px 10px 0px !important;
}

.socialBtn div[class*='-MuiStack-root'] button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
  width: 100%;
  border-radius: 5px !important;
  height: 43px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: inherit;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #dadce0;
  background-color: #fff;
  color: #000;
}

.socialBtn div[class*='-MuiStack-root'] iframe {
  /* width: 100% !important; */
  /* padding: 0px !important; */
  /* margin:0px -10px !important; */
}

.socialBtn div[class*='-MuiStack-root'] iframe div#container {
  padding: 0px !important;
  width: 100%;
  /* border-radius: 10px!important; */
}

/* social login btn */
/* .MuiFormControl-root label {
  top: -5px !important;
} */
.customSelect div[class*='-control'] {
  border-radius: 8px;
}

.customSelect div[class*='-placeholder'] {
  margin-top: -4px;
}

.customSelect div[class*='-Input'],
.customSelect div[class*='-control'],
.customSelect div[class*='-ValueContainer'] {
  height: 53px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.otp_section input {
  flex-grow: 1;
  width: 50px !important;
  height: 40px !important;
  margin: 10px 5px !important;
  border: none;
  border-bottom: 1px solid #c9c9c9;
  outline: none;
}

/* .customBox div {
  justify-content: center;
} */

.defaultAdminBtn {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-weight: 700;
  line-height: 1.7142857142857;
  font-size: 0.9375rem;
  text-transform: capitalize;
  font-family: Inter, sans-serif;
  min-width: 64px;
  padding: 8px 22px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0;
  color: #fff;
  background-color: #2065d1;
  box-shadow: 0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%),
    0px 1px 5px 0px rgb(145 158 171 / 12%);
  width: 100%;
  box-shadow: 0 8px 16px 0 rgb(32 101 209 / 24%);
  height: 48px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.actionbtn {
  min-width: 40px;
  display: inline-block;
}

.css-1j7xypu-MuiButtonBase-root-MuiButton-root span {
  margin-left: 0;
  margin-right: 0;
}

.css-9aro4b-MuiButtonBase-root-MuiButton-root span {
  margin-left: 0;
  margin-right: 0;
}

.iconset svg {
  color: #2065d1;
}

.pagination-container {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 10px;
  justify-content: end;
  flex-direction: row;
}

.pagination-container li {
  /* padding: 5px 10px; */
  box-shadow: 0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%),
    0px 1px 5px 0px rgb(145 158 171 / 12%);
  background: #fff;
  margin-right: 10px;
  border-radius: 5px;
  transition: 0.2s all ease-in-out;
}

.hoverbtn:hover {
  color: #fff !important;
}

.css-1ja87p4-MuiButtonBase-root-MuiButton-root:hover {
  background: #0066cc;
  color: #fff;
  border: none;
}

.pagination-container li:hover {
  background: #0066cc;
  color: #fff;
}

.pagination-container li:hover a {
  color: #fff;
}

.pagination-container li a {
  text-decoration: none;
  padding: 5px 10px;
  display: block;
}

.searchFilter fieldset legend {
  display: none !important;
}

.searchFilter fieldset {
  top: 0 !important;
}

.middelplaceholder label {
  line-height: 33px !important;
}

.selectTextfield .MuiSelect-select {
  line-height: 22px !important;
  padding: 10.5px 14px !important;
}

.custom-error {
  font-size: 0.7rem;
  font-weight: 400;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 3px;
  color: #ff4842 !important;
}

.fixedtable {
  height: calc(100vh - 350px);
}

.bannerautocomplete label {
  top: 0 !important;
}

/* shadab-type-css */
.addbook .avatar-upload .avatar-preview > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.addbook .avatar-upload .avatar-preview {
  position: relative;
  width: 100%;
  height: 250px;
}

.addbook .avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.addbook .avatar-upload .avatar-edit input {
  display: none;
}

.addbook .avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.addbook .avatar-upload {
  position: relative;
}

.addbook .profile-img-area {
  width: 100%;
  height: 250px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%),
    0px 1px 5px 0px rgb(145 158 171 / 12%);
  margin-top: -100px;
  margin-right: auto;
  margin-left: auto;
  margin: auto;
  border: 1px solid rgb(226, 226, 226);
}

.contact-description {
  overflow-y: scroll;
  height: 100px;
}

.react-select .css-1k7wwak-control {
  height: 55px;
}

.react-select .css-q45mhh-control {
  height: 55px;
}

.react-select .css-26l3qy-menu {
  z-index: 100;
}

/* //// custom floating input */

.inputContainer {
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;
  margin-bottom: 17px;
}

.inputContainer .icon {
  color: #637381;
  font-size: 21px;
}

.inputContainer .input {
  border: none;
  border-radius: 7px;
  font-size: 16px;
  padding: 16.5px 0px;
  outline: none;
  background: none;
  z-index: 1;
  width: calc(100% - 100px);
  margin: 0;
  /* display: none; */
}

.inputContainer ::placeholder {
  color: transparent;
}

.inputContainer .label {
  position: absolute;
  top: 20px;
  left: 15px;
  padding: 0 4px;
  background-color: white;
  color: #637381;
  font-size: 1rem;
  transition: 0.5s;
  z-index: 0;
}

.inputContainer small {
  margin-left: 15px;
}

.inputContainer .input:focus + .label {
  top: -11px;
  left: 10px;
  z-index: 10;
  font-size: 14px;
  font-weight: 500;
  color: #007aff;
}

.inputContainer .input:not(:placeholder-shown) + .label {
  top: -11px;
  left: 10px;
  z-index: 10;
  font-size: 14px;
  font-weight: 500;
}

.inputContainer .label-top {
  top: -7px;
  left: 10px;
  z-index: 10;
  font-size: 12px;
  font-weight: 400;
}

.inputContainer .input:focus .item-picker {
  border: 2px solid #007aff;
}

.listing-wrapper {
  width: 100%;
  margin: auto;
  position: absolute;
  box-shadow: 0px 0px 12px 0px #c9c9c9;
  color: #000;
  padding: 15px;
  min-height: 100%;
  z-index: 999999;
  background: #fff;
}

.listing-height {
  overflow-y: scroll;
  height: 200px;
}

.listing-height::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.listing-height::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.listing-height::-webkit-scrollbar-thumb {
  background-color: #0066cc;
}

.listing-wrapper ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listing-wrapper ul li {
  padding: 5px 0;
  position: relative;
  cursor: pointer;
}

.listing-wrapper ul li:hover {
  color: #007aff;
}

.noavailable {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-picker {
  border: 1px solid #dce0e4;
  border-radius: 7px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
}

.activeborder {
  border: 2px solid #007aff;
}

.css-5vxoam {
  position: relative;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  margin-right: 5px;
  margin-top: 5px;
  user-select: none;
  appearance: none;
  max-width: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
}

.MuiAutocomplete-tag span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}

.MuiAutocomplete-tag svg {
  -webkit-tap-highlight-color: transparent;
  color: rgba(0, 0, 0, 0.26);
  font-size: 22px;
  cursor: pointer;
  margin: 0px 5px 0px -6px;
}

.MuiAutocomplete-tag svg {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0;
  font-size: 1.5rem;
}

.create-button {
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.08);
  padding: 3px 5px;
  font-size: 12px;
}

.custom-description {
  height: 100px;
  overflow-y: scroll;
}

.rolelistmodal {
  margin-top: 15px;
}

.rolelistmodal ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.rolelistmodal ul li {
  display: inline-block;
  padding: 5px 8px;
  border: 1px solid #a7a7a7;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
  margin: 0 10px 0 0;
}

.rolelistmodal ul li span {
  margin-left: 10px;
  cursor: pointer;
}

.rolelistmodal ul li span svg {
  color: #ff4842;
}

.rolelistmodal .no-border {
  border: none;
}

.css-lr1r4n-MuiStack-root {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
}
