.thm {
  content: 'Theorem';
  background-color: #dae3f3;
  color: #333333;
  margin: 0;
  padding: 1rem;
}

.theorem {
  content: 'Theorem';
  background-color: #0081cc;
  color: #fff;
  margin: 0;
  padding: 1rem;
}

.lemma {
  background-color: #0081cc;
  color: #fff;
}

.corollary {
  background-color: #0081cc;
  color: #fff;
}

.definition {
  content: 'Definition';
  background-color: #a3d8ff;
  color: #333333;
  margin: 0;
  padding: 1rem;
}

.defn {
  content: 'Definition';
  background-color: #a3d8ff;
  color: #333333 !important;
  margin: 0;
  padding: 1rem;
}

.example {
  background-color: #f0f7ff !important;
  padding-left: 0.5rem;
}

.xca {
  border: 3px solid #0081cc;
  border-radius: 12px;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  /*background-color:#BFDFFB !important;*/
}

.sol {
  content: '► ';
  background-color: #f0f7ff;
  font-size: 16px;
}

.solution {
  content: '► ';
  background-color: #f0f7ff;
}

.tab {
  display: inline-block;
  margin-left: 7px;
}
.highlight {
  background-color: #e0f7fa; /*#ffd966; */
}

a {
  color: #0066cc;
  font-weight: 600;
  padding: 0 2px;
  text-decoration: none;
}

a:hover {
  color: #3399ff; /* Darker shade of the original blue */
}

table {
  padding: 0;
  word-break: initial;
}

table tr {
  border-top: 1px solid #dfe2e5;
  margin: 0;
  padding: 0;
}

table tr:nth-child(2n),
thead {
  background-color: #fafafa;
}

table tr th {
  font-weight: bold;
  border: 1px solid #dfe2e5;
  border-bottom: 0;
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}

table tr td {
  border: 1px solid #dfe2e5;
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}

table tr th:first-child,
table tr td:first-child {
  margin-top: 0;
}

table tr th:last-child,
table tr td:last-child {
  margin-bottom: 0;
}

figcaption {
  text-align: center;
  font-size: 0.8em;
  font-style: italic;
  margin: 0 0 0.8em;
}

.react-date {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  margin: 0;
}
